var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"radius-25"},[_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("report.icons."+_vm.report.label)))]),_c('span',[_vm._v(_vm._s(_vm.$t("report.title."+_vm.report.label)))])],1),_c('sc-dates-range',{staticClass:"ml-3",model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('input',false)}}},[_c('v-icon',[_vm._v("mdi-arrow-u-left-top-bold")])],1)],1),(_vm.items.length)?_c('v-row',{attrs:{"align":"center","align-content":"center"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":_vm.data.length==0},on:{"click":function($event){$event.preventDefault();_vm.d_print=true}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('export-excel',{attrs:{"data":_vm.data,"fields":_vm.excel_fields,"title":_vm.excel_title,"footer":_vm.excel_footer}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.data.length==0}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-data-table',{staticClass:"idcs-fill-width",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"idx","footer-props":_vm.foot_props,"no-data-text":_vm.$t('label.nodata')},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.price)))])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.data.indexOf(item)+1))])]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var pagination = ref.pagination;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length-2}},[_c('i18n',{attrs:{"path":"report.rows"},scopedSlots:_vm._u([{key:"rows",fn:function(){return [_vm._v(_vm._s(pagination.itemsLength))]},proxy:true}],null,true)})],1),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.allCounts))]),_c('td',{attrs:{"align":"right"}},[_c('i18n',{attrs:{"path":"report.total"},scopedSlots:_vm._u([{key:"total",fn:function(){return [_vm._v(_vm._s(_vm._f("currency")(_vm.total)))]},proxy:true}],null,true)})],1)])]}}])})],1),_c('sc-printdata-dialog',{attrs:{"items":_vm.data,"headers":_vm.headers,"report":_vm.report,"range":_vm.range,"filter":_vm.filter},scopedSlots:_vm._u([{key:"tablefooter",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length-1}}),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.allCounts))]),_c('td',{attrs:{"align":"right"}},[_c('i18n',{attrs:{"path":"report.total"},scopedSlots:_vm._u([{key:"total",fn:function(){return [_vm._v(_vm._s(_vm._f("currency")(_vm.total)))]},proxy:true}])})],1)])]},proxy:true}]),model:{value:(_vm.d_print),callback:function ($$v) {_vm.d_print=$$v},expression:"d_print"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }